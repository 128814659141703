import { Input, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { BreakLine } from 'components/charts/ReportParameterSelector/style'
import { Label, WarningText } from 'components/dialogs/Generic/styles'
import { DialogTypes } from 'components/dialogs/index'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import React, { useState } from 'react'
import { toProperCase } from 'services/helpers/canonicalization'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'

export default ({
  title,
  onCancel,
  onComplete,
  type,
  defaultName,
  defaultDescription
}: Props) => {
  const [name, setName] = useState<string>(defaultName || '')
  const [description, setDescription] = useState<string>(defaultDescription)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  return (
    <Modal
      title={title || `Save ${toProperCase(DialogTypes[type])}`}
      visible
      closeIcon={
        <CancelIcon
          width={ButtonHeight.SMALL}
          height={ButtonHeight.SMALL}
          style={{ marginTop: SPACE.tiny * 2.75 }}
        />
      }
      onOk={async () => {
        if (!name.length) return
        setIsLoading(true)
        await onComplete(name, description)
        setIsLoading(false)
      }}
      confirmLoading={isLoading}
      okText={'Save'}
      okButtonProps={{ style: { width: '100%' } }}
      onCancel={onCancel}
      width={500}
      cancelButtonProps={{ hidden: true }}
    >
      <Label>{toProperCase(DialogTypes[type])} Name</Label>
      <Input
        onChange={i => setName(i.target.value)}
        value={name}
        style={{
          border: name.length ? '' : 'red 1px solid'
        }}
      />

      {!name.length && (
        <WarningText>
          {toProperCase(DialogTypes[type])} name is required
        </WarningText>
      )}

      {type === DialogTypes.REPORT && (
        <>
          <BreakLine />
          <div>
            <Label>Description</Label>
            <TextArea
              rows={3}
              placeholder="Report Description"
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={i => setDescription(i.target.value)}
              value={description}
            />
          </div>
        </>
      )}
    </Modal>
  )
}

interface Props {
  title?: string
  onCancel: () => void
  onComplete: (text: string, description?: string) => void
  type: DialogTypes
  defaultName?: string
  defaultDescription?: string
}
