import { Layout, Menu } from 'antd'
import MenuSectionTitle from 'components/elements/SectionTitle/MenuSectionTitle'
import StyledLink from 'components/elements/StyledLink'
import MyAccountMenuItems from 'components/page/layout/Sidebar/MenuItems'
import {
  StyledDividerWrapper,
  StyledImage
} from 'components/page/layout/Sidebar/styles'
import { SideBarContext } from 'navigation/sidebarContext'
import ReportList from 'pages/reports/reports'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import routes from 'routes'
import logo from 'static/logo-with-name.png'

const Sidebar = () => {
  const { isOpen } = useContext(SideBarContext)
  const history = useHistory()
  const pathname = useLocation().pathname

  return (
    <Layout.Sider
      theme="dark"
      collapsible
      width={280}
      collapsed={!isOpen}
      collapsedWidth={50}
      trigger={null}
      style={{ backgroundColor: 'black' }}
    >
      <StyledLink to={routes.dashboard()} path={routes.dashboard()}>
        <StyledImage
          src={logo}
          style={{ visibility: !isOpen ? 'hidden' : null }}
          alt="logo"
          id="header-id"
        />
      </StyledLink>

      <Menu
        selectedKeys={[pathname, '/' + pathname.split('/')[1]]}
        mode="inline"
        theme="dark"
        style={{ backgroundColor: 'black' }}
      >
        <StyledDividerWrapper orientation="left" key={'my-account'}>
          <MenuSectionTitle text={'MY ACCOUNT'} visible={!isOpen} />
        </StyledDividerWrapper>
        {MyAccountMenuItems.map(item => (
          <Menu.Item
            icon={item.icon}
            key={item.path}
            onClick={() => history.push(item.path)}
            style={{ fontSize: 18 }}
          >
            {item.title}
          </Menu.Item>
        ))}
        <StyledDividerWrapper orientation="left" key={'reports'}>
          <MenuSectionTitle text={'REPORTS'} visible={!isOpen} />
        </StyledDividerWrapper>
        {ReportList.map(item => (
          <Menu.Item
            icon={item.icon}
            key={item.path}
            onClick={() => history.push(item.path)}
            style={{ fontSize: 16 }}
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu>
    </Layout.Sider>
  )
}

export default Sidebar
