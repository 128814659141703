import { Col, Row } from 'antd'
import AnalysisWrapper from 'components/charts/AnalysisWrapper'
import { SectionWrapper } from 'components/charts/AnalysisWrapper/style'
import ChartRef from 'components/charts/ChartRef'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import TableTitle from 'components/elements/SectionTitle/TableTitle'
import SelectMenu from 'components/elements/SelectMenu'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import { ReportMeta, ReportNames } from 'pages/reports/reportMeta'
import SingleProductSelector from 'pages/reports/reports/common/productSelector/single'
import {
  initialDrillDownState,
  NoneDrillDown
} from 'pages/reports/reports/common/types'
import {
  useLoadData,
  usePreselectGroup,
  useSelectableMetrics
} from 'pages/reports/reports/demographics/hooks'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import useToggle from 'services/hooks/useToggle'
import { ChartHeight, SPACE } from 'services/styles'
import ChartGroup from './components/chartGroup'
import ParameterSelector from './components/parameterSelector'
import MemoizedTable from './components/table'
import {
  ChartOptions,
  ChartTypes,
  DefaultSelectedMetricsState,
  SelectedMetricsState
} from './types'

const Demographics = React.forwardRef((props, ref) => {
  //  @ts-ignore
  const { chartRef, tableRef } = ref
  //  @ts-ignore
  const { fileName } = props.children

  const { isLoading, setIsLoading } = useContext(ReportContext)

  const [shouldShowIndex, setShouldShowIndex] = useToggle(true)

  const [drillDown, setDrillDown] = useState<SelectDataProps>(
    initialDrillDownState
  )

  const [selectedMetrics, _setSelectedMetrics] = useState<SelectedMetricsState>(
    DefaultSelectedMetricsState
  )

  const selectedPeriodKey = useSelector(ChartSelector.selectedPeriodKey)

  const setSelectedMetrics = (key: ChartOptions, data: SelectDataProps) =>
    _setSelectedMetrics(prevData => ({ ...prevData, [key]: data }))

  const selectableMetrics = useSelectableMetrics(drillDown)

  usePreselectGroup(selectableMetrics, setSelectedMetrics, selectedMetrics)

  const rawData = useLoadData(
    setIsLoading,
    selectedMetrics[ChartOptions.PERIOD],
    selectedMetrics[ChartOptions.GROUP],
    selectedMetrics[ChartOptions.PRODUCT],
    drillDown
  )

  const defaultChartGroupProps = {
    data: rawData,
    shouldShowIndex,
    selectedMetrics
  }

  const renderParamSelector = () => (
    <ParameterSelector
      selectableMetrics={selectableMetrics}
      selectedMetrics={selectedMetrics}
      setSelectedMetrics={setSelectedMetrics}
      setShouldShowIndex={setShouldShowIndex}
      shouldShowIndex={shouldShowIndex}
      drillDown={drillDown}
      setDrillDown={setDrillDown}
    />
  )

  const renderProductsDrillDown = () => {
    const handleOnChange = newValue =>
      setSelectedMetrics(ChartOptions.PRODUCT, newValue)
    return (
      <SingleProductSelector
        options={selectableMetrics[ChartOptions.PRODUCT]}
        value={selectedMetrics[ChartOptions.PRODUCT]}
        onChange={handleOnChange}
      />
    )
  }

  const isRetailerDrillDownEnabled = drillDown.key !== NoneDrillDown

  return (
    <>
      <AnalysisWrapper
        isLoading={isLoading}
        productsSelector={renderProductsDrillDown()}
        parameterSelector={renderParamSelector()}
      >
        <SectionWrapper ref={chartRef} style={{ paddingTop: SPACE.small }}>
          <ChartRef style={{ height: isLoading && ChartHeight.STANDARD }}>
            <div className="hidden-text hidden">
              <h4 style={{ textAlign: 'center' }}>
                {ReportMeta[ReportNames.DEMOGRAPHICS].title}
              </h4>
            </div>
            {isRetailerDrillDownEnabled && (
              <div style={{ width: 150 }} data-html2canvas-ignore="true">
                <SelectMenu
                  selectableMenu={selectableMetrics[ChartOptions.GROUP]}
                  item={selectedMetrics[ChartOptions.GROUP]}
                  setItem={item => {
                    const value = item as SelectDataProps
                    setSelectedMetrics(ChartOptions.GROUP, value)
                  }}
                />
              </div>
            )}
            <Row justify="center" style={{ marginBottom: SPACE.small * 1.5 }}>
              {Object.values(ChartTypes).map((type, i) => (
                <Col
                  xl={{ span: 12 }}
                  lg={{ span: 24 }}
                  key={type}
                  style={{ marginTop: i > 1 && SPACE.large }}
                >
                  <ChartGroup type={type} {...defaultChartGroupProps} />
                </Col>
              ))}
            </Row>

            <div className="hidden-text hidden">
              <p style={{ textAlign: 'center' }}>
                Period ({selectedPeriodKey?.value})
              </p>
              {selectedMetrics?.[ChartOptions.PERIOD]?.value && (
                <p style={{ textAlign: 'center' }}>
                  {selectedMetrics[ChartOptions.PERIOD].value}
                </p>
              )}
            </div>
          </ChartRef>
        </SectionWrapper>

        <SectionWrapper>
          <TableTitle />
          <MemoizedTable
            drillDown={drillDown}
            selectedMetrics={selectedMetrics}
            data={rawData}
            dataTableRef={tableRef}
            fileName={fileName}
          />
          <SampleSizeWarningText />
        </SectionWrapper>
      </AnalysisWrapper>
    </>
  )
})

export default Demographics
