import {
  DBChartCustomGroup,
  SortKey
} from 'components/charts/CustomGroupsList/type'
import { CustomGroup } from 'data/chart/types'
import isEqual from 'lodash/isEqual'

const sortArray = (array, sortProp) => {
  const output = array
    .slice()
    .sort((a, b) => b[sortProp].localeCompare(a[sortProp]))
  sortProp === SortKey.NAME && output.reverse()
  return output
}

export const filterGroups = (
  groups: CustomGroup[],
  text: string,
  sortBy: string
): DBChartCustomGroup[] => {
  const sortedGroups = sortArray(groups, sortBy)
  if (text?.length) {
    return sortedGroups.filter(item =>
      item.name.toLowerCase().includes(text.toLowerCase())
    )
  } else {
    return sortedGroups
  }
}

const cleanSavedGroup = group => {
  const {
    updatedAt,
    createdAt,
    version,
    type,
    isEditable,
    isDeletable,
    sharedOrgs,
    user,
    ...cleanGroup
  } = group
  return cleanGroup
}

export const isGroupsEqual = (groupA, groupB) => {
  return isEqual(cleanSavedGroup(groupB), cleanSavedGroup(groupA))
}
