import {
  Chart,
  ChartArea,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartLegend,
  ChartPlotArea,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  SharedTooltipContext
} from '@progress/kendo-react-charts'
import AnalysisWrapper from 'components/charts/AnalysisWrapper'
import { SectionWrapper } from 'components/charts/AnalysisWrapper/style'
import ChartRef from 'components/charts/ChartRef'
import LegendVisual from 'components/charts/LegendVisual'
import SampleSizeWarningText from 'components/charts/SampleSizeWarningText'
import { pointValue } from 'components/charts/SharedChartTooltip'
import {
  CustomColorBar,
  tooltipColor,
  TooltipItemContainer
} from 'components/charts/SharedChartTooltip/style'
import TableTitle from 'components/elements/SectionTitle/TableTitle'
import { ReportContext } from 'components/page/ReportPage/context'
import { ChartSelector } from 'data/selectors'
import startCase from 'lodash/startCase'
import { ReportMeta, ReportNames } from 'pages/reports/reportMeta'
import { initialDrillDownState } from 'pages/reports/reports/common/types'
import ParamSelector from 'pages/reports/reports/trendedMetrics/components/paramSelector'
import { useLoadData } from 'pages/reports/reports/trendedMetrics/hooks'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { isPercent } from 'services/helpers/axisFormatter'
import { toStartCase } from 'services/helpers/canonicalization'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import useIsScreenLarge from 'services/hooks/useIsScreenLarge'
import { ChartHeight, COLOR, GridLineWidth, SPACE } from 'services/styles'
import { getFontStyle } from 'services/styles/font'
import MultipleProductsSelector from '../common/productSelector/multiple'
import useStandardSelectable from '../common/reportHooks/useStandardSelectable'
import MemoizedTable from './components/table'
import MetricsChartTitle from './components/title'
import { TrendedMetricsFormattedDataset } from './formatData'
import { initialDropdownState, TrendedMetricsToUnit } from './types'

const TrendedMetrics = React.forwardRef((props, ref) => {
  //  @ts-ignore
  const { chartRef, tableRef } = ref
  //  @ts-ignore
  const { fileName } = props.children

  const periodLabels = useSelector(ChartSelector.periodLabels)
  const selectedPeriodKey = useSelector(ChartSelector.selectedPeriodKey)

  const { isLoading, setIsLoading } = useContext(ReportContext)

  const [drillDown, setDrillDown] = useState<SelectDataProps>(
    initialDrillDownState
  )

  const [metric, setMetric] = useState(initialDropdownState)

  const {
    selectableViewOptions,
    selectedGroups,
    selectedProducts,
    setSelectedProducts
  } = useStandardSelectable()

  const isScreenLarge = useIsScreenLarge()

  const [rawData, data] = useLoadData(
    setIsLoading,
    metric,
    drillDown,
    selectedGroups,
    selectedProducts
  )

  const renderData = ({
    data,
    name,
    warningLevels,
    color,
    label
  }: TrendedMetricsFormattedDataset) => (
    <ChartSeriesItem
      type="line"
      data={data}
      name={`${changeTextIfLowNumber(name, Math.max(...warningLevels))}`}
      color={color}
      notes={{
        label: {
          content: index => changeTextIfLowNumber(name, warningLevels[index])
        }
      }}
      key={label}
    />
  )

  const renderAxisLabel = (e: any) =>
    isPercent(metric.key) ? (e.value <= 100 ? e.value : '') : e.value

  const renderTooltip = () => (
    <ChartTooltip
      shared={true}
      background={tooltipColor}
      render={({ categoryText, points }: SharedTooltipContext) => (
        <>
          <div>{categoryText}</div>
          {points.map((point, index) => {
            const pointIndex = point.categoryIndex
            const pointLabel = point.series.notes.label.content(pointIndex)
            return (
              <TooltipItemContainer key={index}>
                <CustomColorBar
                  style={{ backgroundColor: `${point.series.color}` }}
                />
                {pointLabel}: {pointValue(toStartCase(metric.key), point.value)}
              </TooltipItemContainer>
            )
          })}
        </>
      )}
    />
  )

  const renderProductsDrillDown = () => (
    <MultipleProductsSelector
      options={selectableViewOptions.products}
      value={selectedProducts}
      onChange={setSelectedProducts}
    />
  )

  const chartTitle = startCase(metric.key.toLowerCase())
  const legendPosition = isScreenLarge ? 'right' : 'bottom'
  const legendWidth = isScreenLarge
    ? document
        .getElementsByClassName('k-chart-surface')?.[0]
        ?.children?.[0]?.children?.[1]?.children?.[4]?.getBoundingClientRect()
        ?.width + SPACE.medium
    : 0
  const maxValue = Math.max(...data.datasets.map(i => i.data).flat())

  return (
    <>
      <AnalysisWrapper
        isLoading={isLoading}
        productsSelector={renderProductsDrillDown()}
        parameterSelector={
          <ParamSelector
            metric={metric}
            setMetric={setMetric}
            drillDown={drillDown}
            setDrillDown={setDrillDown}
          />
        }
      >
        <SectionWrapper ref={chartRef}>
          <ChartRef>
            <div className="hidden-text hidden">
              <MetricsChartTitle
                title={ReportMeta[ReportNames.TRENDED_METRICS].title}
                legendWidth={legendWidth}
              />
            </div>
            <MetricsChartTitle title={chartTitle} legendWidth={legendWidth} />
            <Chart
              transitions={false}
              style={{ height: ChartHeight.STANDARD, fontSize: 90 }}
            >
              <ChartArea background={'transparent'} />
              <ChartLegend
                position={legendPosition}
                labels={getFontStyle(15)}
                item={{ visual: LegendVisual }}
              />
              <ChartValueAxis>
                <ChartValueAxisItem
                  title={{ text: TrendedMetricsToUnit[metric.key] }}
                  labels={{
                    ...getFontStyle(14),
                    content: renderAxisLabel
                  }}
                  max={
                    isPercent(metric.key)
                      ? 120
                      : maxValue >= 200
                      ? maxValue + 20
                      : 200
                  }
                  plotBands={
                    !isPercent(metric.key) && [
                      {
                        from: 100,
                        to: 9999,
                        color: COLOR.blueButton,
                        opacity: 0.2
                      },
                      { from: 0, to: 100, color: COLOR.danger, opacity: 0.2 }
                    ]
                  }
                  narrowRange={false}
                  majorUnit={isPercent(metric.key) ? 10 : 50}
                  minorGridLines={{ visible: true }}
                />
              </ChartValueAxis>
              <ChartPlotArea />
              {renderTooltip()}
              <ChartAxisDefaults
                majorGridLines={{ color: COLOR.lightGrey + '50' }}
                minorGridLines={{
                  color: COLOR.lightGrey + '50',
                  width: GridLineWidth.MINOR
                }}
              />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  categories={periodLabels}
                  labels={{
                    ...getFontStyle(14),
                    rotation: 'auto',
                    padding: SPACE.tiny
                  }}
                >
                  <ChartCategoryAxisTitle
                    text={`Period (${selectedPeriodKey?.value})`}
                    margin={SPACE.small}
                  />
                </ChartCategoryAxisItem>
              </ChartCategoryAxis>
              <ChartSeries>{data.datasets.map(renderData)}</ChartSeries>
            </Chart>
          </ChartRef>
        </SectionWrapper>
        <SectionWrapper>
          <TableTitle />
          <MemoizedTable
            data={rawData}
            dataTableRef={tableRef}
            fileName={fileName}
            drillDown={drillDown}
            selectedProducts={selectedProducts}
          />
          <SampleSizeWarningText />
        </SectionWrapper>
      </AnalysisWrapper>
    </>
  )
})

export default TrendedMetrics
