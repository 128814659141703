import { Menu, Row, Space } from 'antd'
import {
  SearchInput,
  SearchRowWrapper
} from 'components/charts/CustomGroupsList/style'
import {
  DialogKeys,
  DialogTypes,
  GenericDeleteDialog,
  GenericSaveDialog
} from 'components/dialogs'
import DropdownMenu from 'components/elements/DropdownMenu'
import EmptyData from 'components/elements/EmptyData'
import PageSectionTitle from 'components/elements/SectionTitle/PageSectionTitle'
import { DialogActions } from 'data/actions'
import { UserSelector } from 'data/selectors'
import EmptyReport from 'pages/savedReports/components/SavedReports/components/EmptyReport'
import LoadingReports from 'pages/savedReports/components/SavedReports/components/LoadingReports'
import SavedReportCard from 'pages/savedReports/components/SavedReports/components/SavedReportCard'
import { filterReports } from 'pages/savedReports/helpers'

import { ISavedReport, ReportProps } from 'pages/savedReports/type'
import React, { useState } from 'react'
import { FaSortAlphaDown } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import {
  createReportApi,
  deleteReport,
  getReportsViaId
} from 'services/api/requests/reports/common'
import { DBSavedReports } from 'services/api/requests/reports/types'
import useGetDimension from 'services/hooks/useGetDimension'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'
import { SPACE } from 'services/styles'
import { PAGE_FOOTER_HEIGHT, PAGE_HEADER_HEIGHT } from 'services/styles/misc'

const MinimumHeight = `calc(100% - ${
  PAGE_FOOTER_HEIGHT + SPACE.large + PAGE_HEADER_HEIGHT
}px)`

export default () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [sortType, setSortType] = useState<ReportProps>(ReportProps.MODIFIED)
  const user = useSelector(UserSelector.userSelector)

  const { isLoading, data } = useQuery<DBSavedReports>(
    [QueryKeys.SAVED_REPORTS, user],
    () => getReportsViaId(),
    { initialData: { shared: [], user: [] } }
  )

  const duplicateSavedReport = useUpdateQuery(
    QueryKeys.SAVED_REPORTS,
    createReportApi
  )

  const handleHideDispatchDialog = (key: string) =>
    dispatch(DialogActions.hideDialog({ key }))

  const handleDuplicateReport = async (item: ISavedReport) => {
    const handleOK = async ({ name, description }) => {
      const newReport = { ...item, name, description }
      await duplicateSavedReport.mutateAsync(
        { rawData: newReport },
        {
          onSuccess: () => {
            handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)
            Notification({
              type: 'success',
              message: `Report "${name}" duplicated from "${item.name}"`
            })
          }
        }
      )
    }

    dispatch(
      DialogActions.showDialog({
        key: DialogKeys.SAVE_GENERIC,
        component: (
          <GenericSaveDialog
            title="Duplicate Report"
            onComplete={async (name, description) =>
              await handleOK({ name, description })
            }
            onCancel={() => handleHideDispatchDialog(DialogKeys.SAVE_GENERIC)}
            type={DialogTypes.REPORT}
            defaultName={item.name}
            defaultDescription={item.description}
          />
        )
      })
    )
  }

  const deleteSavedReport = useUpdateQuery(
    QueryKeys.SAVED_REPORTS,
    deleteReport
  )

  const handleDeleteReport = async (item: ISavedReport) => {
    const { id, name } = item

    const handleOK = async () => {
      await deleteSavedReport.mutateAsync(
        { id },
        {
          onSuccess: () => {
            handleHideDispatchDialog(DialogKeys.DELETE_GENERIC)
            Notification({
              type: 'success',
              message: `Report "${name}" deleted`
            })
          }
        }
      )
    }

    dispatch(
      DialogActions.showDialog({
        key: DialogKeys.DELETE_GENERIC,
        component: (
          <GenericDeleteDialog
            fromText={'from the saved reports'}
            onOk={handleOK}
            name={name}
            type={DialogTypes.REPORT}
          />
        )
      })
    )
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => setSortType(ReportProps.NAME)}
        icon={<FaSortAlphaDown style={{ marginRight: SPACE.tiny }} />}
      >
        Name
      </Menu.Item>
      <Menu.Item onClick={() => setSortType(ReportProps.MODIFIED)}>
        Last modified
      </Menu.Item>
      <Menu.Item onClick={() => setSortType(ReportProps.CREATED)}>
        Last created
      </Menu.Item>
    </Menu>
  )

  const renderSavedReports = (title, reports) => (
    <>
      <PageSectionTitle text={title} />
      <Row
        style={{
          marginTop: SPACE.small
        }}
        gutter={SPACE.small}
        justify={!reports.length ? 'center' : 'start'}
      >
        {!reports.length ? (
          <EmptyData descriptionComponent={<h6>No result is found.</h6>} />
        ) : (
          <>
            {reports.map(item => (
              <SavedReportCard
                item={item}
                key={item.id}
                handleDuplicateClick={handleDuplicateReport}
                handleDeleteClick={handleDeleteReport}
              />
            ))}
          </>
        )}
      </Row>
    </>
  )

  const filteredSharedReports =
    !isLoading && filterReports(data.shared, searchText, sortType)
  const filteredYourReports =
    !isLoading && filterReports(data.user, searchText, sortType)
  const [, windowHeight] = useGetDimension()

  // when both shared and user are empty
  const isTotalEmpty = ![...data.shared, ...data.user].length

  // only apply if less than 4 reports in total/shared or users
  const isRequiredGap =
    windowHeight > 710 &&
    [...data.shared, ...data.user].length < 4 &&
    ((!data.shared.length && data.user.length < 4) ||
      (!data.user.length && data.shared.length < 4))
  return (
    <>
      <SearchRowWrapper>
        <SearchInput
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          placeholder="Search for your saved reports"
          style={{ marginRight: SPACE.small }}
        />
        <DropdownMenu menuComponent={menu} text={'Sort By'} />
      </SearchRowWrapper>
      {isLoading && <LoadingReports />}
      {!isLoading && isTotalEmpty && <EmptyReport />}
      <Space
        style={{
          width: '100%',
          minHeight: isRequiredGap && MinimumHeight
        }}
        direction="vertical"
        size={SPACE.small}
      >
        {!!data.shared.length &&
          renderSavedReports('Reports shared with you', filteredSharedReports)}
        {!!data.user.length &&
          renderSavedReports('Your Reports', filteredYourReports)}
      </Space>
    </>
  )
}
