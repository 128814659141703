import { MutableRefObject, useEffect, useState } from 'react'

const useGetDivDimension = (ref: MutableRefObject<any>) => {
  const [containerWidth, setContainerWidth] = useState<number>(0)

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      setContainerWidth(entries[0].contentRect.width)
    })
    observer.observe(ref.current)

    return () => ref.current && observer.unobserve(ref.current)
  }, [])

  return containerWidth
}

export default useGetDivDimension
