import { Spin, Tooltip } from 'antd'
import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import React, { useCallback, useRef } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { List } from 'react-virtualized'
import { SPACE } from 'services/styles'
import {
  ItemDataType,
  RsRefForwardingComponent,
  WithAsProps
} from '../@types/common'
import { DropdownMenuCheckItem } from '../Picker'

import { shallowEqual, useClassNames, useCustom } from '../utils'
import { ValueType } from './MultiCascader'

import './styles/index.less'
import { isSomeChildChecked, isSomeParentChecked } from './utils'

export interface DropdownMenuProps extends WithAsProps {
  tooltipText?: string
  isAgeGroupsSelectable?: boolean
  disabledItemValues: ValueType;
  value?: ValueType;
  childrenKey: string;
  valueKey: string;
  labelKey: string;
  loadingText: string;
  menuWidth: number;
  menuHeight: number;
  cascade?: boolean;
  cascadeData: ItemDataType[][];
  cascadePaths: ItemDataType[];
  uncheckableItemValues: ValueType;
  renderMenuItem?: (itemLabel: React.MouseEventHandler, item: ItemDataType) => React.ReactNode;
  renderMenu?: (
    children: ItemDataType[],
    menu: React.ReactNode,
    parentNode?: ItemDataType
  ) => React.ReactNode;
  onCheck?: (node: ItemDataType, event: React.SyntheticEvent, checked: boolean) => void;
  onSelect?: (
    node: ItemDataType,
    cascadeData: ItemDataType[][],
    cascadePaths: ItemDataType[],
    event: React.SyntheticEvent
  ) => void;
}

const defaultProps: Partial<DropdownMenuProps> = {
  as: 'div',
  disabledItemValues: [],
  uncheckableItemValues: [],
  cascadeData: [],
  cascadePaths: [],
  menuWidth: 156,
  menuHeight: 200,
  childrenKey: 'children',
  valueKey: 'value',
  labelKey: 'label'
}

const DropdownMenu: RsRefForwardingComponent<'div', DropdownMenuProps> = React.forwardRef(
  (props: DropdownMenuProps, ref) => {
    const {
      as: Component,
      classPrefix,
      className,
      cascade,
      cascadeData,
      cascadePaths,
      childrenKey,
      disabledItemValues,
      menuWidth,
      menuHeight,
      uncheckableItemValues,
      value,
      valueKey,
      labelKey,
      loadingText,
      renderMenuItem,
      renderMenu,
      onCheck,
      onSelect,
      tooltipText,
      isAgeGroupsSelectable,
      ...rest
    } = props

    const { merge, prefix } = useClassNames(classPrefix)
    const classes = merge(className, prefix('items'))
    const rtl = useCustom('DropdownMenu')

    const menuColumnRef = useRef(null)

    const getCascadeItems = useCallback(
      (items: ItemDataType[], layer: number, node: ItemDataType, isLeafNode: boolean) => {
        const data = []
        const paths = []

        for (let i = 0; i < cascadeData.length && i < layer; i += 1) {
          data.push(cascadeData[i])
          if (i < layer - 1 && cascadePaths) {
            paths.push(cascadePaths[i])
          }
        }

        paths.push(node)
        if (!isLeafNode) {
          data.push(items)
        }

        return {
          cascadeData: data,
          cascadePaths: paths
        }
      },
      [cascadeData, cascadePaths]
    )

    const handleSelect = useCallback(
      (layer: number, node: any, event: React.SyntheticEvent<HTMLElement>) => {
        const children = node[childrenKey]
        const isLeafNode = isNil(children)
        const items = (children || []).map(item => ({ ...item, parent: node }))

        const { cascadeData, cascadePaths } = getCascadeItems(items, layer + 1, node, isLeafNode)

        onSelect?.(node, cascadeData, cascadePaths, event)
      },
      [childrenKey, getCascadeItems, onSelect]
    )

    const renderCascadeNode = (
      node: any,
      index: number,
      layer: number,
      focus: boolean,
      uncheckable: boolean,
      style: any
    ) => {
      const children = node[childrenKey]
      const nodeValue = node[valueKey]
      const nodeLabel = node[labelKey]

      const disabled = disabledItemValues.some(disabledValue =>
        shallowEqual(disabledValue, nodeValue)
      )

      // Use `value` in keys when If `value` is string or number
      const onlyKey = typeof value === 'number' || typeof value === 'string' ? value : index
      let active = value.some(v => v === nodeValue)

      if (cascade) {
        active = active || isSomeParentChecked(node, value, { valueKey })
      }

      const showTooltip = nodeValue === 'AGE_GROUP' && disabled && !isAgeGroupsSelectable

      return (
        <Tooltip
          key={`${layer}-${onlyKey}`}
          placement="bottom"
          title={showTooltip && tooltipText}
        >
          <DropdownMenuCheckItem
            key={`${layer}-${onlyKey}`}
            as="li"
            disabled={disabled}
            active={active}
            focus={focus}
            // Pass the node as a value to Item, and use it in event callbacks.
            value={nodeValue}
            className={children ? prefix('has-children') : undefined}
            indeterminate={
              cascade && !active && isSomeChildChecked(node, value, { valueKey, childrenKey })
            }
            onSelectItem={(_value, event) => handleSelect(layer, node, event)}
            onCheck={(_value, event, checked) => onCheck?.(node, event, checked)}
            checkable={!uncheckable}
            style={style}
          >
            {renderMenuItem ? renderMenuItem(nodeLabel, node) : <Tooltip
              key={nodeLabel}
              getPopupContainer={() => menuColumnRef.current}
              placement="top"
              title={nodeLabel}
              autoAdjustOverflow={false}
              align={{ offset: [0, 10] }}
              overlayInnerStyle={{ fontSize: 10, minHeight: 'fit-content' }}
              overlayStyle={{ whiteSpace: 'nowrap', maxWidth: 'max-content' }}
            >{nodeLabel}</Tooltip>}
            {children ? <FaAngleRight style={{
              display: 'inline-block',
              marginLeft: 2,
              position: 'absolute',
              top: 8,
              right: 12,
              fontWeight: 500
            }}/> : null}
          </DropdownMenuCheckItem>
        </Tooltip>
      )
    }

    const renderCascade = () => {
      const FIRST_COLUMN_WIDTH = 220
      const styles = { width: (cascadeData.length - 1) * menuWidth + FIRST_COLUMN_WIDTH }
      const columnStyles = { height: menuHeight, width: menuWidth }

      const cascadeNodes = cascadeData.map((children, layer) => {
        let uncheckableCount = 0
        const onlyKey = `${layer}_${children.length}`
        const renderRow = ({ index, style }): React.ReactNode => {
          const item = children[index]
          const uncheckable = uncheckableItemValues.some(uncheckableValue =>
            shallowEqual(uncheckableValue, item[valueKey])
          )
          if (uncheckable) {
            uncheckableCount++
          }
          return renderCascadeNode(
            item,
            index,
            layer,
            cascadePaths[layer] && shallowEqual(cascadePaths[layer][valueKey], item[valueKey]),
            uncheckable,
            style
          )
        }
        const menu = (
          <ul role="listbox">
            <List
              height={menuHeight}
              rowCount={children.length}
              rowHeight={36}
              width={layer === 0 ? FIRST_COLUMN_WIDTH : menuWidth}
              rowRenderer={renderRow}
              itemData={children}/>
          </ul>
        )

        const parentNode = cascadePaths[layer - 1]
        const columnClasses = prefix('column', {
          'column-uncheckable': uncheckableCount === children.length
        })

        const renderMenuElement = () => {
          if (renderMenu) {
            return renderMenu(children, menu, parentNode)
          }

          return parentNode?.loading ? (
            <div className={prefix('column-loading')} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Spin size="large"/>
              <p style={{ marginTop: SPACE.small }}>Loading...</p>
            </div>
          ) : (
            menu
          )
        }

        return (
          <div ref={menuColumnRef} key={onlyKey} className={columnClasses}
            data-layer={layer}
            style={{ ...columnStyles, width: layer === 0 ? FIRST_COLUMN_WIDTH : columnStyles.width }}>
            {renderMenuElement()}
          </div>
        )

      })
      return <div style={styles}>{cascadeNodes}</div>
    }

    return (
      <Component {...rest} ref={ref} className={classes}>
        {renderCascade()}
      </Component>
    )
  }
)

DropdownMenu.displayName = 'DropdownMenu'
DropdownMenu.defaultProps = defaultProps

DropdownMenu.propTypes = {
  classPrefix: PropTypes.string,
  data: PropTypes.array,
  disabledItemValues: PropTypes.array,
  value: PropTypes.array,
  childrenKey: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  menuWidth: PropTypes.number,
  menuHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  cascade: PropTypes.bool,
  cascadeData: PropTypes.array,
  cascadePaths: PropTypes.array,
  uncheckableItemValues: PropTypes.array,
  renderMenuItem: PropTypes.func,
  renderMenu: PropTypes.func,
  onSelect: PropTypes.func,
  onCheck: PropTypes.func
}

export default DropdownMenu
