import OrganizationChart from '@dabeng/react-orgchart'
import { ChartTitle } from 'pages/reports/reports/purchaseDrivers/style'
import { FormattedData } from 'pages/reports/reports/purchaseDrivers/types'
import React from 'react'
import 'regenerator-runtime/runtime'
import { changeTextIfLowNumber } from 'services/helpers/checkIsLowNumber'
import Node from './node'

interface Props {
  data: FormattedData
  index: number
}

export default ({ data, index }: Props) => {
  const { color, tree, group, warningLevel } = data
  const chartName = `chart-${index}`

  return (
    <>
      <ChartTitle style={{ color }}>
        {changeTextIfLowNumber(String(group.value), warningLevel)}
      </ChartTitle>
      <style
        //  @ts-ignore
        jsx
      >{`
        div.orgchart.${chartName} ul li .oc-node:not(:only-child)::after {
          background-color: ${color};
        }

        div.orgchart.${chartName} > ul > li > ul li::before {
          background-color: ${color};
          border-top: 2px solid ${color};
        }

        div.orgchart.${chartName} > ul > li > ul li > .oc-node::before {
          background-color: ${color};
        }
      `}</style>
      <OrganizationChart
        collapsible={false}
        datasource={tree}
        chartClass={chartName}
        NodeTemplate={Node}
      />
    </>
  )
}
