import {
  NextButtonBackground,
  StepperContentTitle,
  StepperWrapper
} from 'components/dialogs/CustomGroup/common/style'
import StepperTitle from 'components/elements/SectionTitle/StepperTitle'
import { ChartSelector } from 'data/selectors'
import { MultiCascader } from 'forkedComponents/rsuite'
import { cleanProductQuery } from 'pages/reports/utils/cleanBody'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

interface Props {
  orgOptions?: any
  selectedProducts: string[]
  handleProductChange: (i: string[]) => void
}

export const StepperDropdown = ({
  orgOptions,
  selectedProducts,
  handleProductChange
}: Props) => {
  let products = useSelector(ChartSelector.productsList)

  if (orgOptions) {
    products = orgOptions?.products
  }

  const [stepperIndex, setStepperIndex] = useState<number>(0)

  const stepperContent = [
    {
      title: 'products',
      content: (
        <div style={{ display: stepperIndex !== 0 && 'none', width: '100%' }}>
          <NextButtonBackground>
            <MultiCascader
              menuWidth={335}
              menuHeight={250}
              menuAutoWidth
              labelKey={'label'}
              valueKey={'value'}
              data={products}
              value={selectedProducts}
              inline
              menuStyle={{ overflow: 'scroll' }}
              onChange={handleProductChange}
            />
          </NextButtonBackground>
        </div>
      ),
      selectedList: cleanProductQuery(selectedProducts)
    }
  ]

  return (
    <>
      {stepperContent.map((i, index) => (
        <StepperWrapper key={index}>
          <StepperContentTitle onClick={_ => setStepperIndex(index)}>
            <StepperTitle
              title={i.title}
              selectedList={i.selectedList}
              active={stepperIndex === index}
            />
          </StepperContentTitle>
          {i.content}
        </StepperWrapper>
      ))}
    </>
  )
}

// const listToProperCase = (array: string[]) =>
//   array.map(i => i.replace(/_/g, ' ')).map(toProperCase)
