import { DialogKeys, InviteDialog } from 'components/dialogs'
import EmptyData from 'components/elements/EmptyData'
import LoadingRows from 'components/elements/LoadingRows'
import SectionTitleWithButton from 'components/elements/SectionTitle/SectionTitleWithButton'
import { DialogActions } from 'data/actions'
import UsersTable from 'pages/organisation/details/components/users/components/UsersTable'
import { DropCap } from 'pages/organisation/details/styles'
import { OrganisationProps } from 'pages/types'
import React from 'react'
import { useIsFetching } from 'react-query'
import { useDispatch } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import { sendInvitationApi } from 'services/api/requests/organisation/users'
import { useUpdateQuery } from 'services/hooks/useUpdateQuery'
import { Notification } from 'services/notification'

interface OrgUserProps {
  orgState: OrganisationProps
  data: any
  currentUser: any
  roles: any
  permission: boolean
}

const OrgUsers = ({
  orgState,
  data,
  currentUser,
  roles,
  permission
}: OrgUserProps) => {
  const dispatch = useDispatch()

  const inviteUser = useUpdateQuery(QueryKeys.ORG_DATA, sendInvitationApi)
  const isLoading = useIsFetching(QueryKeys.ORG_DATA)

  const handleShowDialog = (key, component) =>
    dispatch(DialogActions.showDialog({ key, component }))

  const _handleInviteUser = async ({ email, role }) => {
    await inviteUser.mutateAsync(
      { orgId: orgState.id, email, role },
      {
        onSuccess: () =>
          Notification({
            type: 'success',
            message: 'Invitation Sent'
          })
      }
    )
  }

  const handleInviteUser = () => {
    if (data.length < orgState.purchasedSeats) {
      handleShowDialog(
        DialogKeys.INVITE,
        <InviteDialog
          availableRoles={roles}
          handleSendInvitation={_handleInviteUser}
        />
      )
    } else {
      Notification({
        type: 'error',
        message:
          'You have reached the maximum number of seats purchased, please contact us to purchase additional seats.'
      })
    }
  }

  const isEmpty = !isLoading && !data?.length
  return (
    <div>
      <SectionTitleWithButton
        text={'Users'}
        subtitle={
          <>
            <DropCap>{data?.length || 0}</DropCap>
            <span>{`out of ${orgState.purchasedSeats} seats used`}</span>
          </>
        }
        showButton={permission}
        buttonText={'Invite new user'}
        onClick={handleInviteUser}
      />

      {isLoading ? (
        <LoadingRows />
      ) : (
        <UsersTable
          data={data}
          orgState={orgState}
          loginUser={currentUser}
          roles={roles}
          permission={permission}
        />
      )}

      {isEmpty && (
        <EmptyData
          descriptionComponent={<h6>No users added to this organisation</h6>}
        />
      )}
    </div>
  )
}

export default OrgUsers
