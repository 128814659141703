import arrayToTree from 'array-to-tree'
import axios from 'axios'

import config from 'config'

import { renderTreeRecursive } from 'services/helpers/flatArrayToTree'
import pathToApi from 'services/helpers/pathToApi'
import {
  ChainsEnumToCanonical,
  Channel,
  CombineChainsEnum,
  CombineChainsMapping,
  RetailerGroup
} from 'services/helpers/toChain'

export const listParameters = async () => {
  const url = pathToApi(config.dashboardBaseUrl)
  const { data } = await axios.get(url('/query/list/options'))
  const { retailer, period, products, income, householdSize, ageGroup } = data
  const productsData = formatProducts(products)
  const buyersData = formatBuyerGroup(income, householdSize, ageGroup)
  const retailersData = formatRetailers(retailer)

  return {
    periods: period
      .map(i => parseInt(i))
      .slice()
      .sort((a, b) => b - a),
    products: productsData,
    retailers: retailersData,
    buyersGroups: buyersData
  }
}

export const periodTypeToRobustKey = {
  1: 'robust4Wk',
  3: 'robust12Wk',
  6: 'robust24Wk',
  13: 'robust52Wk'
}

export const filterProductsTree = (array, robustKey) => {
  const getNodes = (result, object) => {
    if (Array.isArray(object.children)) {
      const children = object.children.reduce(getNodes, [])
      if (object[robustKey] && children.length) {
        result.push({ ...object, children })
      }
    } else {
      if (object[robustKey]) {
        result.push(object)
        return result
      }
    }
    return result
  }

  return array.reduce(getNodes, [])
}

export const formatProducts = (productsList: any[]): ProductResponse[] => {
  const treeOutput: TreeInterface[] = []

  const totalAlcoholItem = productsList.find(
    i => i.category === 'TOTAL ALCOHOL'
  )

  if (totalAlcoholItem) {
    treeOutput.push({
      ...totalAlcoholItem,
      robust52Wk: true,
      id: 'TOTAL ALCOHOL',
      name: 'TOTAL ALCOHOL',
      parent_id: undefined
    })
  }

  const filteredProducts = productsList.filter(
    i => i.category !== 'TOTAL ALCOHOL'
  )

  for (const {
    category,
    manufacturer,
    brand,
    subBrand,
    ...rest
  } of filteredProducts) {
    const renamedCategory = `CAT|${category}`
    const renamedManufacturer = `[${renamedCategory}] > PB|${manufacturer}`
    const renamedBrand = `[${renamedManufacturer}] > SB|${brand}`
    const renamedSubBrand = `[${renamedBrand}] > SSB|${subBrand}`

    if (!treeOutput.find(i => i.id === renamedCategory)) {
      treeOutput.push({
        id: renamedCategory,
        name: category,
        parent_id: undefined,
        robust4Wk: true,
        robust12Wk: true,
        robust24Wk: true,
        robust52Wk: true
      })
      treeOutput.push({
        id: `TOTAL ${renamedCategory}`,
        name: `TOTAL ${category}`,
        parent_id: renamedCategory,
        robust4Wk: true,
        robust12Wk: true,
        robust24Wk: true,
        robust52Wk: true
      })
    }

    if (!treeOutput.find(i => i.id === renamedManufacturer)) {
      treeOutput.push({
        id: renamedManufacturer,
        name: manufacturer,
        parent_id: renamedCategory
      })
      treeOutput.push({
        id: `TOTAL ${renamedManufacturer}`,
        name: `TOTAL ${manufacturer}`,
        parent_id: renamedManufacturer
      })
    }

    if (!treeOutput.find(i => i.id === renamedBrand)) {
      const robustValue =
        category.includes('BEER') ||
        category.includes('WINE') ||
        category.includes('SPIRIT')
          ? {}
          : { ...rest, robust52Wk: true }

      treeOutput.push({
        ...robustValue,
        id: renamedBrand,
        name: brand,
        parent_id: renamedManufacturer
      })

      // Temporary: disable sub-brand, unless some RTD brands have sub-brand
      !category.includes('RTD') &&
        treeOutput.push({
          id: `TOTAL ${renamedBrand}`,
          name: `TOTAL ${brand}`,
          parent_id: renamedBrand
        })
    }

    if (
      (category.includes('BEER') ||
        category.includes('WINE') ||
        category.includes('SPIRIT')) &&
      !treeOutput.find(i => i.id === renamedSubBrand)
    ) {
      treeOutput.push({
        ...rest,
        robust52Wk: true,
        id: renamedSubBrand,
        name: subBrand,
        parent_id: renamedBrand
      })
    }
  }

  let robustTreeOutput = treeOutput

  while (robustTreeOutput.some(i => i.robust4Wk === undefined)) {
    robustTreeOutput = robustTreeOutput.map((i, index, arr) => {
      if (i.robust4Wk === undefined) {
        const parentNode = arr.find(j => j.id === i.parent_id)
        const childrenNodes = arr.reduce(
          (acc, j) =>
            j.parent_id === i.id.replace(/(TOTAL )/gi, '') ? [...acc, j] : acc,
          []
        )
        const robustValue =
          childrenNodes.length && childrenNodes.some(i => 'robust4Wk' in i)
            ? childrenNodes.reduce(
                (acc, curr) => ({
                  robust4Wk: curr.robust4Wk || acc.robust4Wk,
                  robust12Wk: curr.robust12Wk || acc.robust12Wk,
                  robust24Wk: curr.robust24Wk || acc.robust24Wk
                }),
                {
                  robust4Wk: false,
                  robust12Wk: false,
                  robust24Wk: false
                }
              )
            : parentNode
        return {
          ...i,
          robust4Wk: robustValue.robust4Wk,
          robust12Wk: robustValue.robust12Wk,
          robust24Wk: robustValue.robust24Wk,
          robust52Wk: true
        }
      }
      return {
        ...i,
        robust52Wk: true
      }
    })
  }

  return renameKey(arrayToTree(robustTreeOutput)).map(renderTreeRecursive)
}

export const formatBuyerGroup = (income, householdSize, ageGroup) => {
  const treeOutput = [
    { id: 'ALL_BUYER_GROUP', name: 'All Buyer Group', parent_id: undefined },
    { id: 'INCOME', name: 'Income', parent_id: undefined },
    { id: 'HOUSEHOLD_SIZE', name: 'Household Size', parent_id: undefined },
    { id: 'AGE_GROUP', name: 'Age Group', parent_id: undefined }
  ]

  for (const eachIncome of income) {
    treeOutput.push({
      id: `INCOME / ${eachIncome}`,
      name: eachIncome,
      parent_id: 'INCOME'
    })
  }

  for (const eachHouseholdSize of householdSize) {
    treeOutput.push({
      id: `HOUSEHOLD_SIZE / ${eachHouseholdSize}`,
      name: eachHouseholdSize,
      parent_id: 'HOUSEHOLD_SIZE'
    })
  }

  for (const eachAgeGroup of ageGroup) {
    treeOutput.push({
      id: `AGE_GROUP / ${eachAgeGroup}`,
      name: eachAgeGroup,
      parent_id: 'AGE_GROUP'
    })
  }
  return renameKey(arrayToTree(treeOutput)).map(renderTreeRecursive)
}

export const formatRetailers = retailers => {
  // temporary disable vintage cellars
  const availableRetailers = retailers.filter(i => i !== 'VINTAGE_CELLARS')

  const singleBanner = availableRetailers.filter(i =>
    CombineChainsMapping[CombineChainsEnum.SINGLE_BANNER].includes(i)
  )
  const retailersGroups = RetailerGroup
  const channel = Channel

  const treeOutput = [
    {
      id: CombineChainsEnum.ALL_BANNERS,
      name: ChainsEnumToCanonical[CombineChainsEnum.ALL_BANNERS],
      parent_id: undefined
    },
    {
      id: CombineChainsEnum.SINGLE_BANNER,
      name: ChainsEnumToCanonical[CombineChainsEnum.SINGLE_BANNER],
      parent_id: undefined
    },
    {
      id: CombineChainsEnum.RETAILER_GROUP,
      name: ChainsEnumToCanonical[CombineChainsEnum.RETAILER_GROUP],
      parent_id: undefined
    },
    {
      id: CombineChainsEnum.CHANNEL,
      name: ChainsEnumToCanonical[CombineChainsEnum.CHANNEL],
      parent_id: undefined
    }
  ] as TreeInterface[]

  for (const banner of singleBanner) {
    treeOutput.push({
      id: `${CombineChainsEnum.SINGLE_BANNER} / ${banner}`,
      name: ChainsEnumToCanonical[banner],
      parent_id: CombineChainsEnum.SINGLE_BANNER
    })
  }

  for (const retailerGroup of retailersGroups) {
    treeOutput.push({
      id: `${CombineChainsEnum.RETAILER_GROUP} / ${retailerGroup}`,
      name: ChainsEnumToCanonical[retailerGroup],
      parent_id: CombineChainsEnum.RETAILER_GROUP
    })
  }

  for (const item of channel) {
    treeOutput.push({
      id: `${CombineChainsEnum.CHANNEL} / ${item}`,
      name: ChainsEnumToCanonical[item],
      parent_id: CombineChainsEnum.CHANNEL
    })
  }

  return renameKey(arrayToTree(treeOutput)).map(renderTreeRecursive)
}

export const renameKey = (input: arrayToTree.Tree<any>[]): any => {
  const output = []
  if (!input) return input
  for (const eachInput of input) {
    const newOutput = {
      ...eachInput,
      id: eachInput.id,
      label: eachInput.name || '',
      children: renameKey(eachInput.children)
    }
    output.push(newOutput)
  }
  return output
}

interface TreeInterface {
  id: string
  parent_id?: string
  name: string
  robust4Wk?: boolean
  robust12Wk?: boolean
  robust24Wk?: boolean
  robust52Wk?: boolean
}

export interface ProductResponse {
  id: string
  children: ProductResponse[]
  label: string
}
