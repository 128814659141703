import { Button } from 'antd'
import { ButtonHTMLType } from 'antd/lib/button/button'
import React, { CSSProperties, ReactNode } from 'react'
import { BORDER_RADIUS, COLOR } from 'services/styles'
import { FontWeight } from 'services/styles/misc'

interface standardProps {
  text?: string
  icon?: ReactNode
  onClick?: (e: any) => void
  style?: any
  disabled?: boolean
  htmlType?: ButtonHTMLType
  isLoading?: boolean
}

const standardButtonStyle: CSSProperties = {
  color: 'white',
  backgroundColor: COLOR.blueButton,
  fontWeight: FontWeight.BOLD,
  borderRadius: BORDER_RADIUS.standard * 3
}

const disabledButtonStyle: CSSProperties = {
  ...standardButtonStyle,
  backgroundColor: COLOR.inputGrey,
  color: COLOR.textGrey,
  border: 0,
  cursor: 'not-allowed'
}

const StandardButton = ({
  text,
  icon,
  onClick,
  style,
  disabled,
  htmlType,
  isLoading
}: standardProps) => {
  const ButtonStyle = disabled
    ? { ...disabledButtonStyle, ...style }
    : { ...standardButtonStyle, ...style }
  return (
    <Button
      loading={isLoading}
      style={ButtonStyle}
      disabled={disabled}
      icon={icon}
      onClick={onClick}
      htmlType={htmlType}
    >
      {text}
    </Button>
  )
}

export default StandardButton
