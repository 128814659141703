import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { PersistConfig, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import reduxThunk from 'redux-thunk'
import rootReducer from './reducers'
import * as sagas from './sagas'
import { UserTransforms } from './transforms'

const rootPersistConfig: PersistConfig<any> = {
  key: 'ROOT',
  storage,
  blacklist: ['chart', 'dialog'],
  transforms: [UserTransforms]
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(reduxThunk, sagaMiddleware))
)

Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware))

const persistor = persistStore(store)

export { store, persistor, sagaMiddleware }
