import { Input, Modal } from 'antd'
import Form from 'antd/lib/form'
import { DialogKeys } from 'components/dialogs/index'
import SelectMenu from 'components/elements/SelectMenu'
import { CancelIcon } from 'components/elements/SvgIcon/styles'
import { DialogActions } from 'data/actions'
import { MessageBlock, StyledForm } from 'pages/auth/style'
import { RoleEnum, roleToText } from 'pages/organisation/types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toStartCase } from 'services/helpers/canonicalization'
import { SelectDataProps } from 'services/helpers/dropdownUtils'
import { emailValidationRules } from 'services/helpers/validationRules'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'

const InviteDialog = ({ availableRoles, handleSendInvitation }) => {
  const dispatch = useDispatch()

  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [role, setRole] = useState<SelectDataProps>({
    key: availableRoles.find(i => i.name === RoleEnum.MEMBER).id,
    value: roleToText[availableRoles.find(i => i.name === RoleEnum.MEMBER).name]
  })

  const handleClose = () =>
    dispatch(DialogActions.hideDialog({ key: DialogKeys.INVITE }))

  const handleComplete = async ({ email, role }) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return setError('Please input a valid email')
    } else {
      setIsLoading(true)
      await handleSendInvitation({ email, role: role.key })
      setIsLoading(false)
      handleClose()
    }
  }

  const rolesMenu = availableRoles.slice(1).map(i => ({
    key: i.id,
    value: toStartCase(roleToText[i.name])
  }))

  return (
    <StyledForm
      key={'invite-form'}
      name="basic"
      labelAlign="left"
      onFinish={handleComplete}
      style={{ maxWidth: 400 }}
    >
      <Modal
        title={'Invite User'}
        visible
        closeIcon={
          <CancelIcon
            width={ButtonHeight.SMALL}
            height={ButtonHeight.SMALL}
            style={{ marginTop: SPACE.tiny * 2.75 }}
          />
        }
        onOk={() => handleComplete({ email, role })}
        confirmLoading={isLoading}
        okText={'Invite'}
        okButtonProps={{ style: { width: '100%' }, htmlType: 'submit' }}
        onCancel={handleClose}
        width={500}
        cancelButtonProps={{ hidden: true }}
      >
        <Form.Item
          key={'invite-form'}
          label={'Email'}
          name={'email'}
          validateFirst
          labelCol={{ span: 24, style: { padding: 0 } }}
          rules={emailValidationRules}
          style={{ marginBottom: SPACE.small }}
        >
          <Input
            placeholder="Please enter an email"
            type="email"
            onChange={i => setEmail(i.target.value)}
            value={email}
          />
        </Form.Item>

        <Form.Item
          key={'invite-form'}
          label={'Role'}
          name={'role'}
          wrapperCol={{ span: 24 }}
          required
        >
          <SelectMenu
            customClass="bg-white"
            placeholder="Please select a role"
            selectableMenu={rolesMenu}
            item={role}
            setItem={setRole}
            style={{ borderRadius: 5 }}
          />
        </Form.Item>
        <MessageBlock>{error}</MessageBlock>
      </Modal>
    </StyledForm>
  )
}
export default InviteDialog
