import { ISavedReport, ReportProps } from './type'

const sortArray = (array, sortProp) => {
  const output = array
    .slice()
    .sort((a, b) => b[sortProp].localeCompare(a[sortProp]))
  if (sortProp === ReportProps.NAME) {
    output.reverse()
  }
  return output
}

export const filterReports = (
  reports: ISavedReport[],
  text: string,
  sortBy: string
): ISavedReport[] => {
  const sortedReport = sortArray(reports, sortBy)
  if (text?.length) {
    return sortedReport.filter(item =>
      item.name.toLowerCase().includes(text.toLowerCase())
    )
  } else {
    return sortedReport
  }
}
