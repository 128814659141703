import { UserSelector } from 'data/selectors'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { QueryKeys } from 'services/api/keys'
import { getUsersApi } from 'services/api/requests/organisation/users'

const initialOrgData = {
  isAdmin: false,
  isSuperAdmin: false,
  roles: [],
  users: []
}

export const useLoadData = orgId => {
  const user = useSelector(UserSelector.userSelector)
  const { isLoading, data } = useQuery(
    [QueryKeys.ORG_DATA, user],
    () => getUsersApi(orgId),
    {
      initialData: initialOrgData
    }
  )

  const formattedOrgData = {
    ...data,
    users: data.users.map(i => ({
      ...i,
      fullName: `${i.firstName ? `${i.firstName} ${i.lastName}` : i.email} `
    }))
  }

  return {
    isLoading,
    data: formattedOrgData
  }
}
