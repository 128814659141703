import { CopyOutlined, DeleteOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import CardComponent from 'components/elements/CardComponent'
import EllipsisText from 'components/elements/EllipsisText'
import StandardButton from 'components/elements/StandardStyledButton'
import { getReportRouteFromKey } from 'pages/reports/reports'
import {
  AbsoluteWrapper,
  MoreBlackIcon,
  RightAlignWrapper
} from 'pages/savedReports/components/SavedReports/styles'
import { ISavedReport } from 'pages/savedReports/type'
import React from 'react'
import { SPACE } from 'services/styles'

interface SavedReportCardProps {
  item: ISavedReport
  handleDeleteClick: (item: ISavedReport) => void
  handleDuplicateClick: (item: ISavedReport) => void
}

const SavedReportCard = ({
  item,
  handleDeleteClick,
  handleDuplicateClick
}: SavedReportCardProps) => {
  const showDropdown = item.isEditable || item.isDeletable || item.isCreator
  const showDuplicate = item.isEditable || item.isCreator
  const showDelete = item.isDeletable || item.isCreator

  const onClickDelete = e => {
    e.stopPropagation()
    handleDeleteClick(item)
  }

  const onClickDuplicate = e => {
    e.stopPropagation()
    handleDuplicateClick(item)
  }

  const to = `${getReportRouteFromKey(item.reportKey)}?report_id=${item.id}`

  const dropdownMenu = (
    <Menu>
      {showDuplicate && (
        <Menu.Item key="duplicate-item">
          <div onClick={onClickDuplicate}>
            <CopyOutlined /> Duplicate
          </div>
        </Menu.Item>
      )}
      {showDelete && (
        <Menu.Item key="delete-item">
          <div onClick={onClickDelete}>
            <DeleteOutlined /> Delete
          </div>
        </Menu.Item>
      )}
    </Menu>
  )
  const renderDropdown = () => (
    <Dropdown trigger={['click']} overlay={dropdownMenu}>
      <div
        style={{
          padding: `${SPACE.small}px ${SPACE.large / 2.5}px`
        }}
        onClick={e => e.stopPropagation()}
      >
        <MoreBlackIcon />
      </div>
    </Dropdown>
  )

  return (
    <CardComponent
      to={to}
      item={{ ...item, title: item.name }}
      ActionComponent={
        showDropdown && <AbsoluteWrapper>{renderDropdown()}</AbsoluteWrapper>
      }
      DescriptionComponent={
        <>
          <EllipsisText text={item.description} maxLine={1} />
          <RightAlignWrapper>
            <StandardButton text={'Run Report'} />
          </RightAlignWrapper>
        </>
      }
      footerMinHeight={100}
    />
  )
}

export default SavedReportCard
