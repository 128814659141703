import { Select } from 'antd'
import { extractItemFromMenu } from 'components/charts/ReportParameterSelector/PeriodSelector/helpers'
import {
  DeleteButton,
  StyledButton
} from 'components/charts/ReportParameterSelector/PeriodSelector/PeriodSelectMenu/styled'
import { CustomPeriod, DialogKeys } from 'components/dialogs'
import FlexContainer from 'components/elements/FlexContainer'
import { PencilIcon } from 'components/elements/SvgIcon/styles'
import { ChartActions, DialogActions } from 'data/actions'
import React, { CSSProperties, Dispatch, SetStateAction } from 'react'
import { useDispatch } from 'react-redux'
import {
  SelectDataProps,
  SelectDataPropsWithId
} from 'services/helpers/dropdownUtils'
import { COLOR } from 'services/styles'

interface props {
  customClass?: string
  selectableMenu: SelectDataPropsWithId[]
  placeholder?: string
  item?: { key: string }
  setItem: Dispatch<SetStateAction<SelectDataPropsWithId>>
  disabledIds?: any[]
  style?: CSSProperties
  groupLabel?: string
}

const itemHeight = 24

const ActionButton = ({ icon, onClick }) => {
  const handleClick = e => {
    e.stopPropagation()
    onClick()
  }

  return <StyledButton type="text" onClick={handleClick} icon={icon} />
}

const PeriodSelectMenu = ({
  customClass,
  placeholder,
  item,
  setItem,
  selectableMenu,
  disabledIds,
  style
}: props) => {
  const dispatch = useDispatch()
  const handleDispatchDialog = (key, component: React.ReactElement) =>
    dispatch(DialogActions.showDialog({ key, component }))

  const groupedOptions = selectableMenu.filter(i => i.id)
  const generalOptions = selectableMenu.filter(i => !i.id)

  const handleEditItem = targetPeriod =>
    handleDispatchDialog(
      DialogKeys.CUSTOM_PERIOD,
      <CustomPeriod prefilledPeriod={targetPeriod} />
    )

  const handleDeleteItem = targetPeriod => {
    const targetIndex = selectableMenu.indexOf(
      extractItemFromMenu(selectableMenu, targetPeriod.id)
    )
    dispatch(ChartActions.deleteCustomPeriod(targetPeriod.id))
    setItem(selectableMenu[targetIndex - 1])
  }

  return (
    <Select
      placeholder={placeholder}
      style={{
        width: '100%',
        minWidth: 150,
        ...style
      }}
      value={item.key}
      optionLabelProp="label"
      className={customClass}
      onChange={(_, option: SelectDataProps) => setItem(option)}
      dropdownAlign={{ overflow: { adjustY: 0 } }}
      defaultActiveFirstOption={false}
    >
      <Select.OptGroup label="PRESET">
        {generalOptions.map(i => (
          <Select.Option
            disabled={disabledIds?.includes(i.key)}
            key={i.key}
            value={i.key}
            label={i.value}
          >
            <FlexContainer
              style={{ minHeight: itemHeight, height: itemHeight }}
            >
              {i.value}
            </FlexContainer>
          </Select.Option>
        ))}
      </Select.OptGroup>

      {groupedOptions.length && (
        <Select.OptGroup label="CUSTOM PERIOD">
          {groupedOptions.map(i => (
            <Select.Option
              disabled={disabledIds?.includes(i.key)}
              key={i.key}
              value={i.id}
              label={i.value}
            >
              <FlexContainer
                style={{
                  minHeight: itemHeight,
                  height: itemHeight
                }}
              >
                <span>{i.value}</span>
                <span>
                  <ActionButton
                    icon={
                      <PencilIcon
                        color={COLOR.textGrey}
                        fill={COLOR.textDark}
                      />
                    }
                    onClick={() => handleEditItem(i)}
                  />
                  <ActionButton
                    icon={<DeleteButton />}
                    onClick={() => handleDeleteItem(i)}
                  />
                </span>
              </FlexContainer>
            </Select.Option>
          ))}
        </Select.OptGroup>
      )}
    </Select>
  )
}

export default PeriodSelectMenu
