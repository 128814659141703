import { Checkbox, Table } from 'antd'
import { BillingInfoProps } from 'pages/types'
import React from 'react'

interface props {
  isDataLoading: boolean
  data: any
  onDataUpdate?: any
  editable: boolean
}

const PurchasedCategoriesTable = ({
  isDataLoading,
  data,
  onDataUpdate,
  editable
}: props) => {
  const handleEdit = ({
    record,
    field
  }: {
    record: BillingInfoProps
    field: string
  }) => {
    const newData = [...data]
    const newItem = newData[record.key]
    const editColumn = !newData[record.key][field]

    if (field === 'all') {
      newData.splice(record.key, 1, {
        ...newItem,
        [field]: editColumn,
        total: true
      })
    } else {
      newData.splice(record.key, 1, {
        ...newItem,
        [field]: editColumn
      })
    }
    onDataUpdate(newData)
  }

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Category Total',
      dataIndex: 'total',
      key: 'total',
      width: '15%',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            disabled={!editable || (editable && record.all)}
            checked={record.total}
            onClick={() => editable && handleEdit({ record, field: 'total' })}
          />
        </div>
      )
    },
    {
      title: 'Category Details',
      dataIndex: 'all',
      key: 'all',
      width: '15%',
      render: (text, record) =>
        record.all !== null && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              disabled={!editable}
              checked={record.all}
              onClick={() => editable && handleEdit({ record, field: 'all' })}
            />
          </div>
        )
    }
  ]

  return (
    <Table
      loading={isDataLoading}
      columns={columns}
      dataSource={data}
      pagination={false}
      style={{ textAlign: 'left' }}
      scroll={{ y: 300 }}
    />
  )
}

export default PurchasedCategoriesTable
