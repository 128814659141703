import { Row } from 'antd'
import Form from 'antd/lib/form'
import StandardButton from 'components/elements/StandardStyledButton'
import camelCase from 'lodash/camelCase'
import { MessageBlock, PasswordInput, StyledForm } from 'pages/auth/style'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import routes from 'routes'
import { requestPasswordResetApi } from 'services/api/requests/resetPassword'
import { passwordValidationRules } from 'services/helpers/validationRules'
import { SPACE } from 'services/styles'
import { ButtonHeight } from 'services/styles/misc'
import logo from 'static/logo-with-name-black.png'
import Header from '../RegisterHeader'

const ResetComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const key = urlParams.get('key')

  const [isLoading, setLoading] = useState<boolean>(false)
  const [sentRequest, setSentRequest] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [token, setToken] = useState<any>({ id: '', code: '' })

  const singleLevelCamelCase = (data: object) =>
    Object.entries(data).reduce((acc, entries) => {
      const [key, value] = entries
      acc[camelCase(key)] = value
      return acc
    }, {})

  useEffect(() => {
    if (key) {
      const decodedKey = singleLevelCamelCase(JSON.parse(atob(key)))
      setToken(decodedKey)
    }
  }, [key])

  const handleCreatePassword = async ({ password }) => {
    const { id, code } = token
    setError('')
    setLoading(true)
    try {
      const response = await requestPasswordResetApi({
        id,
        code,
        password
      })
      if (response) {
        setSentRequest(true)
      }
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }

  const renderForm = () => {
    return (
      <StyledForm
        key={'reset-form'}
        name="basic"
        labelAlign="left"
        onFinish={handleCreatePassword}
        style={{ maxWidth: 400 }}
      >
        <Form.Item
          key={'reset-form'}
          label={'Password'}
          name={'password'}
          validateFirst
          labelCol={{ span: 24, style: { padding: 0 } }}
          rules={passwordValidationRules}
          style={{ marginBottom: SPACE.small }}
        >
          <PasswordInput />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <StandardButton
            style={{
              height: ButtonHeight.MEDIUM,
              width: 400
            }}
            text="Reset"
            htmlType="submit"
            isLoading={isLoading}
          />
        </Form.Item>

        <MessageBlock>{error}</MessageBlock>
      </StyledForm>
    )
  }

  const renderMessage = text => (
    <Row style={{ maxWidth: 400 }}>
      <p style={{ marginBottom: SPACE.small }}>{text}</p>
      <StandardButton
        style={{
          height: ButtonHeight.MEDIUM,
          width: 400
        }}
        text="Return to login"
        onClick={() => history.push(routes.auth.login())}
      />
    </Row>
  )

  return (
    <>
      <img src={logo} style={{ height: 60, margin: SPACE.large }} alt="logo" />
      {key && (
        <>
          <Header title={'Create New Password'} />
          {sentRequest
            ? renderMessage('Your password has been changed successfully')
            : renderForm()}
        </>
      )}
    </>
  )
}

export default ResetComponent
