import chunk from 'lodash/chunk'
import moment from 'moment'
import { separator } from 'pages/reports/reports/common/helpers'

export const dateToWeekEnding = {
  2001: '02/02/20',
  2002: '01/03/20',
  2003: '29/03/20',
  2004: '26/04/20',
  2005: '24/05/20',
  2006: '21/06/20',
  2007: '19/07/20',
  2008: '16/08/20',
  2009: '13/09/20',
  2010: '11/10/20',
  2011: '08/11/20',
  2012: '06/12/20',
  2013: '03/01/21',
  2101: '31/01/21',
  2102: '28/02/21',
  2103: '28/03/21',
  2104: '25/04/21',
  2105: '23/05/21',
  2106: '20/06/21',
  2107: '18/07/21',
  2108: '15/08/21',
  2109: '12/09/21',
  2110: '10/10/21',
  2111: '07/11/21',
  2112: '05/12/21',
  2113: '02/01/22',
  2201: '30/01/22',
  2202: '27/02/22',
  2203: '27/03/22',
  2204: '24/04/22',
  2205: '22/05/22',
  2206: '19/06/22',
  2207: '17/07/22',
  2208: '14/08/22',
  2209: '11/09/22',
  2210: '09/10/22',
  2211: '06/11/22',
  2212: '04/12/22',
  2213: '01/01/23'
}

export const monthMMtoMMM = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'July',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
}

interface RollingPeriodProps {
  startDate?: number
  numberOfPeriod: number | string
  periodType: number | string
  rollingType: number | string
  descendingPeriods: number[]
}

export const toWeekStartingMoment = (weekEnding: string) => {
  const [day, month, year] = weekEnding.split('/')
  return moment(`20${year}-${month}-${day}`)
    .subtract(27, 'days')
    .format('DD MMMM YYYY')
}

export const toMomentDate = (rawDate: string) => {
  const [day, month, year] = rawDate.split('/')
  return moment(`20${year}-${month}-${day}`).format('DD MMMM YYYY')
}

export const periodToText = (rollingPeriod: number[]) =>
  `${(rollingPeriod.length || 1) * 4} WE ${
    dateToWeekEnding[rollingPeriod[rollingPeriod.length - 1]]
  }`

export const extractDateFromPeriod = (text: string) =>
  text?.match(/\d+\/\d+\/\d+/g)?.[0]

export const textToPeriod = (text: string) =>
  parseInt(
    Object.entries(dateToWeekEnding).find(
      ([key, value]) => value === extractDateFromPeriod(text)
    )?.[0]
  )

export const toSelectedKey = (
  numberOfPeriod,
  periodType,
  rollingType,
  datePointer,
  dateMode
) =>
  `${numberOfPeriod}${separator}${periodType}${separator}${rollingType}${separator}${datePointer}${separator}${dateMode}`

export const toRollingPeriod = ({
  startDate,
  numberOfPeriod,
  periodType,
  rollingType,
  descendingPeriods
}: RollingPeriodProps) => {
  const startDateIndex = descendingPeriods.findIndex(i => i === startDate)

  const selectedRangePeriod = startDate
    ? descendingPeriods.slice(0, startDateIndex + 1).sort((a, b) => a - b)
    : descendingPeriods

  const rollingPeriods = chunk(
    selectedRangePeriod.reduce((acc, curr, index, array) => {
      const delay = index * +rollingType
      return index > array.length - +periodType
        ? acc
        : [
            ...acc,
            ...array.slice(delay, delay + +periodType).sort((a, b) => a - b)
          ]
    }, []),
    +periodType
  )

  const selectedNumberOfRolling = rollingPeriods.slice(0, +numberOfPeriod)

  return startDate
    ? selectedNumberOfRolling
    : selectedNumberOfRolling.slice().reverse()
}
