import { color } from 'color-blend'
import { CustomGroup } from 'data/chart/types'
import { getCleanGroupSignature } from 'pages/reports/reports/common/helpers'
import { SELECT_ALL_PRODUCT_KEY } from 'pages/reports/reports/common/reportHooks/usePreselectProduct'
import {
  FormatData,
  ParamsSelectors,
  RetailersToColor,
  toColor
} from 'pages/reports/reports/crossPurchase/types'
import { ChainsEnumToCanonical } from 'services/helpers/toChain'
import { COLOR } from 'services/styles'

export const formatData = (
  data: any[],
  customGroups: CustomGroup[],
  { selectedGroups, selectedProducts }: Partial<ParamsSelectors>
): FormatData[] =>
  data?.reduce((acc, curr) => {
    const isProductsDrillDownEnabled = !selectedProducts.find(
      i => i.key === SELECT_ALL_PRODUCT_KEY
    )

    const [dataName, cleanId] = getCleanGroupSignature(
      customGroups,
      curr.key,
      false,
      isProductsDrillDownEnabled
    )

    const localGroup = customGroups.find(
      group => group.id === cleanId || group.id === curr.key
    )

    return selectedGroups.find(
      group => group.key === cleanId || group.key === curr.key
    )
      ? [
          ...acc,
          {
            ...localGroup,
            datasets: curr.data.map(j => ({
              warningLevel: j.warningLevel,
              group: dataName,
              data: Number(j.data?.toFixed(1)),
              key: j.key
            })),
            name: dataName,
            id: curr.key
          }
        ]
      : acc
  }, [])

export const findRetailerColor = (retailerName: string) => {
  const individual = retailerName.split('|')
  const mixedColor = individual.reduce(
    (acc, curr) => color(acc, RetailersToColor[curr]),
    RetailersToColor[individual[0]]
  )
  const defaultColor = COLOR.darkTransparent
  return (mixedColor && toColor(mixedColor)) || defaultColor
}

export const formatTooltipData = (data: string): any => {
  const keys = data.split('|').map(i => i.trim())
  return keys.map(i => ChainsEnumToCanonical[i]).join(' & ')
}
